@separator_color: #e1e1e1;
@grey_text: #979797;
@clear_text_button: #b3b3b3;
@clear_text_button_toggle: #8b8b8b;
@border-color: #ccc;
@primary: #0866ac;
@hover: #84b3d6;

#issuesMainDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    #issuesHeader {
        #issueSearchBarDiv {
            position: relative;
            margin-bottom: 8px;

            #issueSearchBarLeftIcon {
                transform: translate(0, -50%);
                position: absolute;
                left: 5px;
                top: 49%;
            }

            #issuesSearchBar {
                &::-ms-clear {  display: none; }
                width: 100%;
                border-radius: 0;
                padding: 0 20px;
                height: 32px;
            }

            #issueSearchBarRightIcon {
                color: @clear_text_button;
                transform: translate(0, -50%);
                position: absolute;
                visibility: hidden;
                cursor: pointer;
                right: 10px;
                top: 50%;
                &:hover {
                    color: @clear_text_button_toggle;
                }
            }
        }

        #issuesTabsMenu {
            margin-top: 8px;
            border-bottom: 1px solid #dddddd;
            margin: auto;
            width: 100%;

            .issuesNavItem {
                cursor: pointer;
                width:50%;

                .issuesNavLink {
                    text-align: center;
                }
            }
        }
    }

    #issuesScroll {
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1;

        > div.error {
            color: red;
            font-style: italic;
            margin: 5px;
        }

        #issuesCenter {
            margin-top: 10px;
            text-align: center;
            width: 100%;

            #issuesNoIssue {
                color: grey;
                font-style: italic;
            }
        }

        &.hideDescription > .issuesContainer > .issuesDescription {
            display: none;
        }

        .issuesContainer {
            cursor: pointer;
            margin: 5px;
            border: 1px solid @separator_color;

            &:hover{
                background-color: @hover;

                .issuesDescription:after, .issuesDescription:before {
                    background-color: @hover;
                }
            }

            .issuesTitleContainer {
                display: flex;
                flex-direction: row;

                .issuesTitle {
                    margin: 5px;
                    margin-right: 0px;
                    font-weight: bold;
                    width: 90%;

                    .issuesId {
                        font-weight: normal;
                        color: @grey_text;
                    }
                }

                .issuesCommentsCounter {
                    margin: 5px;
                    color: @grey_text;
                    text-align: right;
                    white-space: nowrap;
                }
            }

            .issuesDescription {
                overflow: hidden;
                position: relative;
                line-height: 1.2em;
                max-height: 3.6em;
                text-align: justify;
                margin-left: 5px;
                margin-right: 5px;

                &:before {
                    content: "...";
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    background: #FFF;
                }

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    width: 1em;
                    height: 1em;
                    margin-top: 0.2em;
                    background: #FFF;
                }
            }
        }
    }

    #issuesFooter {
        border-top: 1px solid @separator_color;
        width: 100%;
        text-align: center;
        padding-bottom: 15px;
        button {
            margin-top: 15px;
        }

        .exportCSV {
            margin-right: 15px;
        }
    }
}

/**************** Style for issueView ****************/
#issuePopup {
    .modal-dialog, .modal-content {
        width: 750px;
    }
}
#issuePopup {
    #issueModalHeader {
        display: flex;
        justify-content: space-between;

        #issueIcon {
            background: url(../images/Warning-03_16x16.png) no-repeat;
            height: 16px;
            width: 20px;
            margin-top: 4px;
            margin-right: 0px;
        }

        #issueCloseButton {
            margin-left: 10px;
            padding-top: 5px;
            height: 15px;
        }

        #issueNumber {
            padding-top: 3px;
            margin-left: 5px;
            font-style: italic;
            color: @grey_text;
        }

        .invalid {
            border: 1px solid #dc3545 !important;
        }

        #issueTitleBar {
            flex: 1;
            min-height:24px;
            height: 26px;
            resize: none;
            border-width: 0px;
            font-weight: bold;
            outline: none;
            padding: 2px 6px;

            &:focus {
                border-width: 1px;
                margin: -1px;
                margin-bottom: 1px;
            }
        }

        #issueTitleBar:disabled{
            background-color: white;
        }
    }

    #issueModalBody {
        margin: 0px 5px 0px 5px;

        #issueDescriptionTextArea:disabled {
            background-color: white;
        }

        #issueRelatedElementBar, #issueassignedToBar, #issueModelBar{
            width: 80%;
        }

        div#issueModelGroup {
            div.item {
                display: flex;
                cursor: pointer;
            }

            span.name {
                padding-top: 3px;
                padding-left: 10px;
            }

            .item[data-mimetype*='bpmn'] i[class^='img-'] {
                .generate-product("bpmn", "\e600", #0866AC);
            }

            .item[data-mimetype*='cmmn'] i[class^='img-'] {
                .generate-product("cmmn", "\e601", #16884A);
            }

            .item[data-mimetype*='discovery'] i[class^='img-'] {
                .generate-product("discovery", "\e602", #ED5724);
            }

            .item[data-mimetype*='dmn'] i[class^='img-'] {
                .generate-product("dmn", "\e603",#EFA91B);
            }

            .item[data-mimetype*='landscaping'] i[class^='img-'] {
                .generate-product("landscaping", "\e902",#e72c2e);
            }

            .item[data-mimetype*='capability'] i[class^='img-'] {
                .generate-product("capability", "\e904", #7B67AE);
            }

            .item[data-mimetype*='businessentity'] i[class^='img-'] {
                .generate-product("businessentity", "\e908", #e5185c);
            }

            .item[data-mimetype*='pmml'] i[class^='img-'] {
                .generate-product("pmml", "\e90c", #848484);
            }
        }

        .typeahead.dropdown-menu {
            border-radius: 0;
            border-color: @border-color;
            width: 80%;
            li a {
                text-overflow: ellipsis;
                overflow-x: hidden;
                cursor: pointer;
            }
        }

        .form-group label {
            display: block;
        }

        #issueComments {
            border-top: 1px solid @clear_text_button;
            margin-top: 25px;

            .richtext {
                min-height: 50px;
            }
        }

        .richtext {
            min-height: 105px;
        }
    }

    .mce-tinymce .mce-edit-area.mce-panel {
        border: 0 !important;
    }

    .mce-panel, .mce-tinymce {
        box-shadow: none !important;
        border-color: @border-color !important;
    }
}

.generate-product(@mime, @content, @color) {
    background-image: none;
    font-family: 'trisotech-product';
    font-style: normal;
    font-size: 20px;
    margin-top: 0px;
    speak: none;

    &:before {
        content: @content;
        color: @color;
    }
}
